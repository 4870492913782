import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/bonus',
      name: 'bonus',
      component: () => import('@/views/bonus/list/Display.vue'),
      meta: {
        pageTitle: 'Bonus Liste',
        breadcrumb: [
          {
            text: 'Bonus Liste',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/bonus-detail/:bonusId',
      name: 'bonus-detail',
      component: () => import('@/views/bonus/detail/Display.vue'),
      meta: {
        pageTitle: 'Bonus Detail',
        breadcrumb: [
          {
            text: 'Bonus Detail',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/user-detail/:userId/:bonusId',
      name: 'user-detail',
      component: () => import('@/views/bonus/user/detail/Display.vue'),
      meta: {
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/fin',
      name: 'fin',
      component: () => import('@/views/fin/list/Display.vue'),
      meta: {
        pageTitle: 'Fahrzeugidentifikationsnummer',
        breadcrumb: [
          {
            text: 'Fahrzeugidentifikationsnummer Liste',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/charging-station',
      name: 'charging-station',
      component: () => import('@/views/charging-station/list/Display.vue'),
      meta: {
        pageTitle: 'Ladestellen',
        breadcrumb: [
          {
            text: 'Ladestellen Liste',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    /* AUTH */
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    /* Settings */
    {
      path: '/settings/mail-attachment',
      name: 'partner-mail-attachments',
      component: () => import('@/views/partner/MailAttachments.vue'),
      meta: {
        pageTitle: 'E-Mail-Anhänge',
        breadcrumb: [
          {
            text: 'Konfigurator',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/settings/bulk-email-list',
      name: 'partner-bulk-email-list',
      component: () => import('@/views/partner/BulkEmailList.vue'),
      meta: {
        pageTitle: 'Massenmailing',
        breadcrumb: [
          {
            text: 'Liste',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/settings/bulk-email-new',
      name: 'partner-bulk-email-new',
      component: () => import('@/views/partner/BulkEmailNew.vue'),
      meta: {
        pageTitle: 'Massenmailing',
        breadcrumb: [
          {
            text: 'Senden',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/settings/delete-entries',
      name: 'delete-entries',
      component: () => import('@/views/backoffice/BackofficeDeleteVin.vue'),
      meta: {
        pageTitle: 'FIN löschen',
        breadcrumb: [
          {
            text: '',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/settings/delete-entries-user',
      name: 'delete-entries-user',
      component: () => import('@/views/backoffice/BackofficeDeleteUser.vue'),
      meta: {
        pageTitle: 'Benutzer löschen',
        breadcrumb: [
          {
            text: '',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    {
      path: '/settings/csv-import',
      name: 'csv-import',
      component: () => import('@/views/backoffice/CsvImport.vue'),
      meta: {
        pageTitle: 'CSV Import',
        breadcrumb: [
          {
            text: '',
            active: true,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    /* SYSTEM */
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
