import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCookies from 'vue-cookies'
//  import VueSSE from 'vue-sse'
import VueFriendlyIframe from 'vue-friendly-iframe'

import moment from 'moment'
import 'moment/locale/de'

//  number formatting
import numeral from 'numeral'
import numFormat from 'vue-filter-number-format'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  currency: {
    symbol: '€',
  },
})

numeral.locale('de')
moment.locale('de')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

//  Cookies
Vue.use(VueCookies)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(require('vue-moment'), {
  moment,
})

//  numberformatting
Vue.filter('numFormat', numFormat(numeral))
Vue.filter('formatDate', value => moment(String(value)).format('DD/MM/YYYY'))
Vue.filter('formatTime', value => moment(String(value)).format('hh:mm'))

//  sse
//  Vue.use(VueSSE)

//  iframe
Vue.use(VueFriendlyIframe)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
